import React from "react"

import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeartBroken } from "@fortawesome/free-solid-svg-icons"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <p style={{ fontSize: 100, color: `rgba(255,0,0,0.64)` }}>
      <FontAwesomeIcon icon={faHeartBroken} />
    </p>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>

    <br />
    <Link to="/#go">Go back to the homepage</Link>
  </Layout>
)

export default NotFoundPage
